import { useState, useEffect, useCallback } from "preact/hooks";

export enum Theme {
    Dark = "dark",
    Light = "light",
    FollowOs = "followOs"
}

const createTheme = (theme: string): Theme => {
    switch (theme) {
        case "dark":
            return Theme.Dark;
        case "light":
            return Theme.Light;
        case "followOs":
            return Theme.FollowOs;
        default:
            return Theme.Dark;
    }
};

const setLocalStorageTheme = (theme: Theme | null) => {
    try {
        localStorage && localStorage.setItem("theme", JSON.stringify(theme));
    } catch (err) {
        console.warn("Can’t write to local storage:", err.message);
    }
};
const getMql = () => {
    if (typeof window !== "undefined") {
        return window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)");
    }
};

const getSystemTheme = () => {
    const mql = getMql();

    return mql && mql.matches ? Theme.Dark : Theme.Light;
};

const setThemeOnHTMLTag = (theme: Theme) => {
    if (theme === Theme.FollowOs) {
        console.error("You're trying to set theme on HTML to", theme);
    } else {
        document.getElementsByTagName("HTML")[0].setAttribute("data-theme", theme);
    }
};

const getLocalStorageTheme = () => {
    try {
        const localTheme = localStorage && localStorage.getItem("theme");
        if (localTheme != null) {
            const theme = createTheme(JSON.parse(localTheme));
            if ([Theme.Dark, Theme.Light, Theme.FollowOs].includes(theme)) {
                return theme;
            }
        }
        return null;
    } catch (err) {
        console.warn("Can’t access local storage:", err.message);
        return null;
    }
};
export function useTheme() {
    const [userTheme, setUserTheme] = useState<Theme | null>(null);
    const [systemTheme, setSystemTheme] = useState<Theme.Dark | Theme.Light>(getSystemTheme());

    const updateTheme = useCallback((theme: Theme, systemTheme: Theme.Dark | Theme.Light) => {
        setLocalStorageTheme(theme);
        setThemeOnHTMLTag(theme === Theme.FollowOs ? systemTheme : theme);
    }, []);

    const mql = getMql();
    const mqlListener = (e: MediaQueryListEvent) => {
        setSystemTheme(e.matches ? Theme.Dark : Theme.Light);
    };

    const chooseTheme = (theme: Theme) => {
        setUserTheme(theme);
    };

    useEffect(() => {
        userTheme != null && updateTheme(userTheme, systemTheme);
    }, [systemTheme, userTheme, updateTheme]);

    useEffect(() => {
        const localStorageTheme = getLocalStorageTheme();
        switch (localStorageTheme) {
            case Theme.Dark:
                setUserTheme(Theme.Dark);
                break;
            case Theme.Light:
                setUserTheme(Theme.Light);
                break;
            case Theme.FollowOs:
                setUserTheme(Theme.FollowOs);
                break;
            case null: {
                if (userTheme == null) setUserTheme(Theme.FollowOs);
                break;
            }
        }
        mql && mql.addListener(mqlListener);
        return () => mql && mql.removeListener(mqlListener);
    }, []);

    return {
        theme: userTheme,
        chooseTheme
    };
}
