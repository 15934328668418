import { h } from "preact";
import { KnowledgeStrength, SkillTypeID } from "data/enums";
import { MySkills } from "data/SkillsData";

interface Props {
    levelId: KnowledgeStrength;
    selectedCategory: SkillTypeID;
}
export const SkillCategoryLevels = ({ levelId, selectedCategory }: Props) => {
    const strong = SkillTypeID.Languages == selectedCategory ? "Fluent" : "Strong";
    const knowing = SkillTypeID.Languages == selectedCategory ? "Basic" : "Knowledgeable";
    const levelLabel = levelId == KnowledgeStrength.Knowledgeable ? knowing : strong;
    return (
        <div className={`skillsList --level-${levelId}`}>
            {<div className="label">{levelLabel}</div>}
            {MySkills.filter(skill => skill.skillCategoryId === selectedCategory && skill.levelId === levelId).map(({ name }) => {
                return (
                    <div className="skill" key={name}>
                        {name}
                    </div>
                );
            })}
        </div>
    );
};
