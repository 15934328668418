import { h } from "preact";

import { roles } from "data/Data";
import { FrontEndDeveloperIds } from "data/enums";
import { CustomH1 } from "components/atoms/customH1";

interface Props {
    jobrole: number;
    handleSelectRole: (id: FrontEndDeveloperIds) => void;
}
export const JobRoles = ({ jobrole, handleSelectRole }: Props) => {
    const handleClick = (id: FrontEndDeveloperIds) => {
        handleSelectRole(id);
    };
    return (
        <div className="jobRoles">
            {roles.map(({ id, title }) => (
                <CustomH1 key={id} outlined id={id} onClick={() => handleClick(id)} role="button" active={jobrole === id}>
                    {title}
                </CustomH1>
            ))}
        </div>
    );
};
