import { ComponentChildren, h } from "preact";
import { ModalManager } from "services/modalManager/ModalManager";

export interface Props {
    onRequestClose?: Function;
    children?: ComponentChildren;
    enableOverlayClose?: boolean;
    closeButton?: boolean;
    okButton?: boolean;
    submitLabel?: string;
    dismissLabel?: string;
    withDismiss?: boolean;
}
export const Modal = ({ children, enableOverlayClose, closeButton, okButton, submitLabel, dismissLabel, withDismiss, onRequestClose }: Props) => {
    const close = () => {
        if (!onRequestClose || onRequestClose()) {
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            ModalManager.dismiss();
        }
    };
    const handleKeyDown = (e: KeyboardEvent) => {
        if (e.keyCode === 27 /*esc*/) close();
    };
    const stopPropagation = (ev: MouseEvent) => {
        ev.stopPropagation();
    };

    return (
        <div
            className="modal-overlay"
            onClick={() => {
                if (enableOverlayClose) close();
            }}>
            <div className="modal-content" onClick={stopPropagation} onKeyDown={handleKeyDown}>
                {closeButton && (
                    <button tabIndex={0} onKeyDown={handleKeyDown} className="close" onClick={close}>
                        &#215;
                    </button>
                )}
                <div className="modal-body">{children}</div>
                {(withDismiss || dismissLabel || okButton !== false) && (
                    <div className="modal-footer">
                        {(withDismiss || dismissLabel) && (
                            <button className="btn cancel" onClick={close} tabIndex={0} onKeyPress={e => e.key === "Enter" && close()}>
                                {dismissLabel ? dismissLabel : "cancel"}
                            </button>
                        )}
                        {okButton !== false && (
                            <button tabIndex={0} className="btn submit" onKeyPress={e => e.key === "Enter" && ModalManager.submit()} onClick={() => ModalManager.submit()}>
                                {submitLabel ? submitLabel : "ok"}
                            </button>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};
