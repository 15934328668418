import { h } from "preact";
import { useState } from "preact/hooks";
import "./Skills.scss";
import { SkillCategory } from "./SkillCategory";
import { SkillCategoryLevels } from "./SkillCategoryLevels";
import { KnowledgeStrength } from "data/enums";
import { AppPages } from "components/templates/appPages";

interface Props {
    rightAlign?: boolean;
}
export const Skills = ({ rightAlign = false }: Props) => {
    const [selectedCategory, showCategory] = useState(0);

    return (
        <AppPages pageClass="skills kd0" rightAlign={rightAlign} title="Skills">
            <SkillCategory showCategory={showCategory} selectedCategory={selectedCategory} />

            <SkillCategoryLevels levelId={KnowledgeStrength.Strong} selectedCategory={selectedCategory} />
            <SkillCategoryLevels levelId={KnowledgeStrength.Knowledgeable} selectedCategory={selectedCategory} />
        </AppPages>
    );
};

// const mapStateToProps = ({ isMobile }) => ({ isMobile });

// export default connect(mapStateToProps)(SkillsSection);

// const SkillsContainer = styled.div`
//     font-size: 1rem;
//     font-weight: 700;
//     text-align: left;
//     text-transform: uppercase;
//     width: 100%;

//     @media ${respondAbove.md} {
//         display: flex;
//         justify-content: space-between;
//         > div {
//             flex: 1;
//         }
//     }
// `;
// const Skills = styled.div`
//     @media ${respondBelow.md} {
//         display: none;
//     }

//     @media ${respondAbove.md} {
//         padding: 50px 0 0 20px;
//         flex: 1;
//         display: flex;
//         justify-content: space-between;
//         overflow: hidden;
//     }
// `;
