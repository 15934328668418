import { h } from "preact";
import "./Educations.scss";
import { dataEducation } from "data/Data";
import { EducationWrapper } from "components/organisms/educationWrapper";
import { AppPages } from "components/templates/appPages";

interface Props {
    rightAlign?: boolean;
}

export const Educations = ({ rightAlign }: Props) => {
    return (
        <AppPages pageClass="educations l3o" title="Education" rightAlign={rightAlign}>
            {dataEducation.map(education => (
                <EducationWrapper key={education.name} education={education} />
            ))}
        </AppPages>
    );
};
