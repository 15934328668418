import { KnowledgeStrength, SkillTypeID } from "./enums";
import { Skill } from "./types";

export const Bash: Skill = {
    name: "Bash",
    skillCategoryId: SkillTypeID.Tools,
    levelId: KnowledgeStrength.Strong
};

export const OhMyZshell: Skill = {
    name: "Oh-my-zsh",
    skillCategoryId: SkillTypeID.Tools,
    levelId: KnowledgeStrength.Strong
};
export const Bootstrap: Skill = {
    name: "Bootstrap",
    skillCategoryId: SkillTypeID.Libraries,
    levelId: KnowledgeStrength.Knowledgeable
};
export const CocoaPods: Skill = {
    name: "CocoaPods",
    skillCategoryId: SkillTypeID.Bundlers,
    levelId: KnowledgeStrength.Knowledgeable
};
export const CSharp: Skill = {
    name: "C#",
    skillCategoryId: SkillTypeID.Programming,
    levelId: KnowledgeStrength.Knowledgeable
};
export const Git: Skill = {
    name: "Git",
    pdfName: "Git/Gitflow",
    skillCategoryId: SkillTypeID.Bundlers,
    levelId: KnowledgeStrength.Strong
};
export const Gitflow: Skill = {
    name: "Gitflow",
    skillCategoryId: SkillTypeID.Bundlers,
    levelId: KnowledgeStrength.Strong
};
export const Html: Skill = {
    name: "HTML",
    pdfName: "HTML/Twig",
    skillCategoryId: SkillTypeID.Frameworks,
    levelId: KnowledgeStrength.Strong
};
export const Javascript: Skill = {
    name: "Javascript",
    pdfName: "ReactJs/Javascript",
    skillCategoryId: SkillTypeID.Programming,
    levelId: KnowledgeStrength.Strong
};
export const Java: Skill = {
    name: "Java",
    skillCategoryId: SkillTypeID.Programming,
    levelId: KnowledgeStrength.Knowledgeable
};
export const Laravel: Skill = {
    name: "Laravel",
    skillCategoryId: SkillTypeID.Libraries,
    levelId: KnowledgeStrength.Knowledgeable
};
export const MySQL: Skill = {
    name: "MySQL",
    skillCategoryId: SkillTypeID.Libraries,
    levelId: KnowledgeStrength.Knowledgeable
};
export const TypeScript: Skill = {
    name: "TypeScript",
    skillCategoryId: SkillTypeID.Libraries,
    levelId: KnowledgeStrength.Knowledgeable
};
export const NodeJs: Skill = {
    name: "Node.Js",
    skillCategoryId: SkillTypeID.Bundlers,
    levelId: KnowledgeStrength.Knowledgeable
};
export const Npm: Skill = {
    name: "NPM",
    skillCategoryId: SkillTypeID.Bundlers,
    levelId: KnowledgeStrength.Strong
};
export const Parse: Skill = {
    name: "Parse",
    skillCategoryId: SkillTypeID.Bundlers,
    levelId: KnowledgeStrength.Knowledgeable
};
export const Pyramid: Skill = {
    name: "Pyramid",
    pdfName: "Pyramid/Chameleon",
    skillCategoryId: SkillTypeID.Frameworks,
    levelId: KnowledgeStrength.Knowledgeable
};
export const Chameleon: Skill = {
    name: "Chameleon",
    skillCategoryId: SkillTypeID.Frameworks,
    levelId: KnowledgeStrength.Knowledgeable
};
export const Python: Skill = {
    name: "Python",
    skillCategoryId: SkillTypeID.Programming,
    levelId: KnowledgeStrength.Strong
};
export const Recompose: Skill = {
    name: "Recompose",
    skillCategoryId: SkillTypeID.Libraries,
    levelId: KnowledgeStrength.Strong
};
export const Redux: Skill = {
    name: "Redux",
    pdfName: "Redux/-saga",
    skillCategoryId: SkillTypeID.Libraries,
    levelId: KnowledgeStrength.Strong
};
export const ReduxSaga: Skill = {
    name: "Redux-Saga",
    skillCategoryId: SkillTypeID.Libraries,
    levelId: KnowledgeStrength.Strong
};
export const Sass: Skill = {
    name: "Sass",
    pdfName: "Sass/Styled-Components",
    skillCategoryId: SkillTypeID.Libraries,
    levelId: KnowledgeStrength.Strong
};
export const Scrum: Skill = {
    name: "Scrum",
    skillCategoryId: SkillTypeID.Frameworks,
    levelId: KnowledgeStrength.Strong
};
export const Sketch: Skill = {
    name: "Sketch",
    skillCategoryId: SkillTypeID.Tools,
    levelId: KnowledgeStrength.Knowledgeable
};
export const StyledComponents: Skill = {
    name: "Styled-Components",
    skillCategoryId: SkillTypeID.Libraries,
    levelId: KnowledgeStrength.Strong
};
export const Swift: Skill = {
    name: "Swift",
    skillCategoryId: SkillTypeID.Programming,
    levelId: KnowledgeStrength.Knowledgeable
};
export const Swift2: Skill = {
    name: "Swift 2.0",
    skillCategoryId: SkillTypeID.Programming,
    levelId: KnowledgeStrength.Knowledgeable
};
export const Twig: Skill = {
    name: "Twig",
    skillCategoryId: SkillTypeID.Frameworks,
    levelId: KnowledgeStrength.Knowledgeable
};
export const Gsap: Skill = {
    name: "GSAP",
    skillCategoryId: SkillTypeID.Libraries,
    levelId: KnowledgeStrength.Knowledgeable
};
export const VueJs: Skill = {
    name: "VueJs",
    skillCategoryId: SkillTypeID.Frameworks,
    levelId: KnowledgeStrength.Knowledgeable
};
export const AngularJs: Skill = {
    name: "AngularJs",
    skillCategoryId: SkillTypeID.Frameworks,
    levelId: KnowledgeStrength.Knowledgeable
};
export const Webpack: Skill = {
    name: "Webpack",
    pdfName: "Yarn/Webpack/Babel",
    skillCategoryId: SkillTypeID.Bundlers,
    levelId: KnowledgeStrength.Strong
};
export const Babel: Skill = {
    name: "Babel",
    skillCategoryId: SkillTypeID.Bundlers,
    levelId: KnowledgeStrength.Strong
};
export const Wordpress: Skill = {
    name: "Wordpress",
    skillCategoryId: SkillTypeID.Libraries,
    levelId: KnowledgeStrength.Knowledgeable
};
export const Xcode: Skill = {
    name: "Xcode",
    skillCategoryId: SkillTypeID.Tools,
    levelId: KnowledgeStrength.Knowledgeable
};
export const Valet: Skill = {
    name: "Valet",
    skillCategoryId: SkillTypeID.Bundlers,
    levelId: KnowledgeStrength.Knowledgeable
};
export const Yarn: Skill = {
    name: "Yarn",
    skillCategoryId: SkillTypeID.Bundlers,
    levelId: KnowledgeStrength.Strong
};
export const Grunt: Skill = {
    name: "Grunt",
    pdfName: "Grunt/Gulp",
    skillCategoryId: SkillTypeID.Bundlers,
    levelId: KnowledgeStrength.Knowledgeable
};
export const Gulp: Skill = {
    name: "Gulp",
    skillCategoryId: SkillTypeID.Bundlers,
    levelId: KnowledgeStrength.Knowledgeable
};
export const Danish: Skill = {
    name: "Danish",
    skillCategoryId: SkillTypeID.Languages,
    levelId: KnowledgeStrength.Strong
};
export const English: Skill = {
    name: "English",
    skillCategoryId: SkillTypeID.Languages,
    levelId: KnowledgeStrength.Strong
};
export const Kurdish: Skill = {
    name: "Kurdish",
    skillCategoryId: SkillTypeID.Languages,
    levelId: KnowledgeStrength.Knowledgeable
};
export const Turkish: Skill = {
    name: "Turkish",
    skillCategoryId: SkillTypeID.Languages,
    levelId: KnowledgeStrength.Knowledgeable
};
export const Docker: Skill = {
    name: "Docker",
    skillCategoryId: SkillTypeID.Tools,
    levelId: KnowledgeStrength.Knowledgeable
};
export const ReactJs: Skill = {
    name: "ReactJs",
    skillCategoryId: SkillTypeID.Frameworks,
    levelId: KnowledgeStrength.Strong
};

export const MySkills: Skill[] = [
    // here Starts Programming Languages
    Javascript,
    Python,
    Swift,
    Java,
    CSharp,
    // here Starts Web Technologies
    Sass,
    Redux,
    ReduxSaga,
    Recompose,
    StyledComponents,
    Gsap,
    Bootstrap,
    MySQL,
    Laravel,
    Wordpress,
    NodeJs,
    // FRAMEWORK
    ReactJs,
    Scrum,
    VueJs,
    Pyramid,
    Chameleon,
    Html,
    Twig,
    // here Start Bundlers and others
    Webpack,
    Babel,
    Yarn,
    Npm,
    Git,
    Gitflow,
    Valet,
    Grunt,
    Gulp,
    CocoaPods,
    // here Starts Tools
    Bash,
    Docker,
    Sketch,
    Xcode,
    // here Starts Languages
    Danish,
    English,
    Kurdish,
    Turkish
];
export const MyPDFSkills: Skill[] = [Javascript, Sass, Redux, Html, Git, Webpack, Python, Pyramid, VueJs, MySQL, NodeJs, Docker];
export const MyPDFLanguages: Skill[] = [Danish, English, Kurdish, Turkish];
