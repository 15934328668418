import { h } from "preact";
import "./Arrow.scss";

import ArrowSvg from "assets/icons/arrow.svg";

export const Arrow = () => (
    <div className={"arrow"}>
        <ArrowSvg />
    </div>
);
