import { h, render } from "preact";
import "./ModalManager.scss";

let node: HTMLDivElement;
const modals: Array<h.JSX.Element> = [];
let promiseInfo: {
    resolve: (value?: string) => void;
    reject: (reason?: string | undefined) => void;
};

const renderModal = () => {
    if (modals.length === 0) return;

    const component = modals.shift();
    if (!node) {
        node = document.createElement("div");
        node.classList.add("modal", "show");
        document.body.appendChild(node);
    }
    node.classList.add("show");
    render(component, node);
};

const removeModal = () => {
    node.classList.remove("show");
    setTimeout(() => {
        render("", node); //preact doesn't have unmountnode so this is the hack.
        renderModal(); // render the other modals which are waiting.
    }, 1000);
};

export const ModalManager = {
    open: async (component: h.JSX.Element) => {
        console.log("object");
        return new Promise<string>((resolve, reject) => {
            promiseInfo = {
                resolve,
                reject
            };

            modals.push(component);

            if (modals.length >= 1) {
                renderModal();
            }
        });
    },
    dismiss() {
        promiseInfo?.resolve(undefined);
        removeModal();
    },
    submit() {
        promiseInfo?.resolve("submit");
        removeModal();
    }
};
