import { ComponentChildren, h } from "preact";
import { Arrow } from "../arrow";
import "./CustomH1.scss";

interface Props {
    outlined?: boolean;
    active?: boolean;
    arrow?: boolean;
    role?: string;
    id?: string | number;
    onClick?: () => void;
    children?: ComponentChildren;
}
export const CustomH1 = ({ outlined = false, active = false, arrow = false, children, onClick }: Props) => {
    const handleClick = () => {
        onClick && onClick();
    };
    const handleKeyPress = (e: KeyboardEvent) => {
        if (e.key === "Enter") {
            handleClick();
        }
    };
    return (
        <h1 className={`h1 --outlined-${outlined} --active-${active}`} onClick={handleClick} onKeyPress={handleKeyPress}>
            {children}
            {arrow && <Arrow />}
        </h1>
    );
};
