import { h } from "preact";
import { useState } from "preact/hooks";
import "./Introduction.scss";

import { ModalManager } from "services";
import { ThemeModal } from "components/modals";

import { Menu } from "components/organisms/menu";
import { AppPages } from "components/templates/appPages";

interface Props {
    rightAlign?: boolean;
}
export const Introduction = ({ rightAlign = false }: Props) => {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => setMenuOpen(!menuOpen);
    const openThemeSelector = () => ModalManager.open(<ThemeModal />);

    return (
        <AppPages pageClass="intro" rightAlign={rightAlign} title={"Front-end\nDeveloper"}>
            <div className="header">
                <div className="name">Haci Kale</div>
                <button onClick={openThemeSelector}>Themes</button>
                <button className="menuButton" onClick={toggleMenu}>
                    <div> {menuOpen ? "CLOSE" : "MENU"}</div>
                </button>
                <Menu menuOpen={menuOpen} />
            </div>

            <div
                className={"description"}
                dangerouslySetInnerHTML={{
                    __html:
                        "“Hi and welcome to my playground!\n I create responsive websites using\n(P)ReactJs, Typescript and Sass. this is where I get to be creative and try out new programming tools.”"
                }}
            />

            {/* <ScrollIcon inProp={showScroll} /> */}
        </AppPages>
    );
};
