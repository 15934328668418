import { h } from "preact";
import { useState } from "preact/compat";
import { Button } from "components/atoms/button/Button";
import "./Menu.scss";

interface Props {
    menuOpen: boolean;
}
export const Menu = ({ menuOpen }: Props) => {
    const [copied, setCopied] = useState(false);

    const copyEmail = () => {
        setCopied(true);

        // COPY Email
        navigator.clipboard.writeText("kale.hk@gmail.com");

        setTimeout(() => setCopied(false), 5000);
    };
    return (
        <div className={`menuContainer r5d --open-${menuOpen}`}>
            <div className={"menu"}>
                <button className={"item"} onClick={copyEmail}>
                    {copied ? "Copied!" : "Contact"}
                </button>
                <a className={"link"} href="https://github.com/HaciKale" rel="noreferrer" target="_blank">
                    GitHub
                </a>
                <a className={"link"} href="https://linkedin.com/in/haci-kale/" rel="noreferrer" target="_blank">
                    linkedin
                </a>
            </div>
        </div>
    );
};
