import { h } from "preact";

import "./ThemeModal.scss";

import { Modal } from "../container/Modal";
import { Theme, useTheme } from "components/hooks/useTheme";
import { ModalManager } from "services";
import { CustomH1 } from "components/atoms/customH1";

export const ThemeModal = () => {
    const { theme: selectedTheme, chooseTheme } = useTheme();
    const onThemeClick = (theme: Theme) => {
        chooseTheme(theme);
        setTimeout(() => {
            ModalManager.dismiss();
        }, 700);
    };
    console.log(selectedTheme);
    return (
        <Modal closeButton okButton={false}>
            <div className="themeModal s32">
                <CustomH1>Select Theme</CustomH1>
                <button className={`light --active-${selectedTheme === Theme.Light}`} onClick={() => onThemeClick(Theme.Light)} disabled={selectedTheme === Theme.Light}>
                    Light
                </button>
                <button className={`dark --active-${selectedTheme === Theme.Dark}`} onClick={() => onThemeClick(Theme.Dark)} disabled={selectedTheme === Theme.Dark}>
                    Dark
                </button>
                <button className={`os --active-${selectedTheme === Theme.FollowOs}`} onClick={() => onThemeClick(Theme.FollowOs)} disabled={selectedTheme === Theme.FollowOs}>
                    Follow OS
                </button>
            </div>
        </Modal>
    );
};
