import { h } from "preact";
import { jobs } from "data/Data";
import { JobData } from "data/types";

import { Modal } from "components/modals/container/Modal";
import { ModalManager } from "services";
import { CustomH1 } from "components/atoms/customH1";
interface Props {
    jobrole: number;
    handleOpenJob: (data: JobData, name: string) => void;
}
export const JobPositions = ({ jobrole, handleOpenJob }: Props) => {
    const openModal = () => {
        ModalManager.open(
            <Modal okButton={false} closeButton>
                <div>Hey</div>
            </Modal>
        );
    };
    return (
        <div className="jobPositions">
            {jobs
                .filter(({ roleId }) => roleId === jobrole)
                .map(({ name, data }) => (
                    <div
                        role="button"
                        tabIndex={0}
                        key={name}
                        onClick={() => {
                            openModal();
                        }}
                        onKeyPress={({ key }) => key === "Enter" && handleOpenJob(data, name)}>
                        <CustomH1 arrow id={`${name}`}>
                            <span>{name}</span>
                        </CustomH1>
                    </div>
                ))}
        </div>
    );
};
