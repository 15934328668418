import { h } from "preact";
import { Education } from "data/types";

import "./EducationWrapper.scss";
import { Arrow } from "components/atoms/arrow";

interface Props {
    education: Education;
}
export const EducationWrapper = ({ education }: Props) => {
    const { title, periodStart, periodEnd, name, link, id } = education;
    return (
        <div className={`education --id-${id}`} key={title}>
            <div className={"educationYear"}>{`${periodStart}-${periodEnd}`}</div>
            <div className={"educationTitle"}>{title}</div>
            <div className={"educationName"}>
                <a rel="noopener noreferrer" target="_blank" href={link}>
                    {name}
                    {
                        // <Mobile>
                        //     <Arrow />
                        // </Mobile>
                    }
                </a>
                {
                    // <Desktop>
                    // </Desktop>
                }
                <Arrow />
            </div>
        </div>
    );
};
