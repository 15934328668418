import { h } from "preact";
import "./App.scss";

import { Introduction } from "components/sections/introduction";
import { WorkExperience } from "components/sections/workExperience";
import { Educations } from "components/sections/educations";
import { Skills } from "components/sections/skills";
import { useTheme } from "components/hooks/useTheme";

export const App = () => {
    useTheme();
    return (
        <div id="app" className={`app`}>
            <link href="https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Condensed:wght@400;700&display=swap" rel="stylesheet" />
            <Introduction />
            <WorkExperience rightAlign />
            <Skills />
            <Educations rightAlign />
        </div>
    );
};
