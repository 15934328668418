import { ComponentChildren, h } from "preact";
import "./AppPages.scss";
import { SectionTitle } from "../../organisms/sectionTitle";

interface Props {
    pageClass: string;
    title: string;
    children: ComponentChildren;
    id?: string;
    rightAlign?: boolean;
}

export const AppPages = ({ pageClass, title, children, id, rightAlign }: Props) => (
    <div id={id} className={`appPages K8h ${pageClass ?? ""}`}>
        {title && <SectionTitle title={title} rightAlign={rightAlign} />}
        {children}
    </div>
);
