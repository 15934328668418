export enum SkillTypeID {
    Frameworks,
    Programming,
    Libraries,
    Bundlers,
    Tools,
    Languages
}
export enum FrontEndDeveloperIds {
    Web,
    Mobile
}
export enum FrontEndDeveloperTitles {
    Web = "Front-end Developer",
    Mobile = "iOS Swift Developer"
}
export enum KnowledgeStrength {
    Strong,
    Knowledgeable
}
