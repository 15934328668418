import { h } from "preact";
import "./SectionTitle.scss";

interface Props {
    title: string;
    rightAlign?: boolean;
}
export const SectionTitle = ({ title, rightAlign }: Props) => {
    return <div className={`sectionTitle --rightAlign-${rightAlign}`} dangerouslySetInnerHTML={{ __html: title }} />;
};
