import { h } from "preact";
import { useState } from "preact/hooks";

import "./WorkExperience.scss";
import { JobRoles } from "./JobRoles";
import { JobPositions } from "./JobPositions";

import { JobData } from "data/types";
import { AppPages } from "components/templates/appPages";

interface Props {
    rightAlign?: boolean;
}
export const WorkExperience = ({ rightAlign = false }: Props) => {
    const [jobrole, setJobrole] = useState(1);
    // const [open, setOpen] = useState(false);
    // const [data, setData] = useState<JobData>(jobs[1].data);
    // const [companyName, setCompanyName] = useState("Nuuday");

    // const handleCloseModal = () => {
    //      setOpen(false);
    //     console.error("not implementedz");
    // };
    const handleSelectRole = (id: number) => {
        setJobrole(id);
    };

    const handleOpenJob = (data: JobData, companyName: string) => {
        // setOpen(true);
        // setData(data);
        // setCompanyName(companyName);
        console.error("not implemented", data, companyName);
    };

    return (
        <AppPages pageClass={`workExperience y5x`} rightAlign={rightAlign} title={"Work\nExperience"}>
            {/* <JobModal open={open} closeAction={handleCloseModal} data={data} name={companyName} /> */}
            <JobRoles handleSelectRole={handleSelectRole} jobrole={jobrole} />
            <JobPositions jobrole={jobrole} handleOpenJob={handleOpenJob} />
        </AppPages>
    );
};
