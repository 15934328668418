import { h } from "preact";
import { skillCategory } from "data/Data";
import { CustomH1 } from "components/atoms/customH1";
interface Props {
    selectedCategory: number;
    showCategory: (id: number) => void;
}
export const SkillCategory = ({ selectedCategory, showCategory }: Props) => {
    const handleClick = (id: number) => {
        showCategory(id);
    };
    const handleKeyPress = (e: KeyboardEvent, id: number) => {
        if (e.key === "Enter") {
            handleClick(id);
        }
    };
    return (
        <div className="skillCategory">
            {skillCategory.map(({ category, id }) => {
                return (
                    <div key={category} role="button" tabIndex={0} onClick={() => handleClick(id)} onKeyPress={e => handleKeyPress(e, id)}>
                        <CustomH1 outlined active={id === selectedCategory}>
                            {category}
                        </CustomH1>
                    </div>
                );
            })}
        </div>
    );
};
// export const SkillCategoryStyled = styled.div`
//     cursor: pointer;
//     margin-bottom: 5rem;
//     @media and ${respondAbove.md} {
//         padding-right: 2rem;
//     }
// `;
